<!-- 热招职位详情 -->
<template>
  <div class="wrap">
    <div class="box" v-loading="loading">
      <div class="left">
        <p class="jd-title">
          {{data.jd_title}}
          <span class="small">
            招
            {{data.jd_people_min}}
            人
          </span>
        </p>
        <span style="color:#DC513E;font-size:22px">{{data.jd_salary_min}}-{{data.jd_salary_max}}元</span>
        <!-- <div class="show_title_left">职位介绍</div> -->
        <div class="position">
          <p>岗位类别：{{data.jd_position_name}}</p>
          <p>经验要求：{{data.work}}</p>

          <p>学历要求：{{data.edu}}</p>
        </div>
        <div class="show_title_left">职位诱惑</div>
        <p class="job-hot">
          <span v-for="(item,index) in data.jd_tags_name" :key="index">{{item}}</span>
        </p>
        <div class="show_title_left">职位描述</div>
        <div class="description" v-html="data.jd_desc">
          <!-- {{data.jd_desc}} -->
          <!-- <pre>
      1、电话和微信的形式与客户沟通，挖掘客户需求； 
      2、与客户进行有效沟通，解决客户痛点达成合作；
      3、定期与合作客户进行沟通，建立良好长期合作关系
          </pre>-->
        </div>
        <div class="show_title_left">工作地点</div>
        <p
          v-if="data.jd_place_data"
        >{{data.jd_place_data.province_name}}{{data.jd_place_data.city_name}}{{data.jd_place_data.district_name}}</p>
      </div>

      <div class="right">
        <div class="show_title_left">职位发布人</div>
        <div class="img-logo">
          <img :src="data.company_logo" alt />
          <span>{{data.company_name}}</span>
        </div>

        <div class="show_title_left">公司环境</div>
        <div class="company_width">
          <el-carousel :interval="4000" autoplay>
            <el-carousel-item v-for="(item, index) in data.jd_img_data" :key="index">
              <img :src="item" alt />
              <span></span>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getCompanyjdinfo } from "@/api/company/company";
import { educationBackground, work_time } from "@/utils/searchtype";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      data: {},
      //公司环境照片数组
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let id = this.$route.query.id;
    console.log();
    getCompanyjdinfo({ id: id }).then((res) => {
      if (res.code === 0) {
        this.loading = false;
        this.data = res.data;
        this.data.jd_desc = this.data.jd_desc.replace(/\n/g, "<br/>");
        this.data.work = work_time[this.data.jd_exp_id].label;
        this.data.edu = educationBackground[this.data.jd_edu_id].label;
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  padding-bottom: 0;
}
.box {
  overflow: hidden;
  background-color: #fff;
  padding: 10px 50px 30px;
}
.small {
  font-size: 12px;
  border: 1px solid $main2_color;
}

.left {
  float: left;
  width: 70%;
  border-right: 1px solid #f4f4f5;
}
.right {
  float: right;
  width: 29%;
}
.img-logo span {
  margin-left: 20px;

  font-size: 12px;
  line-height: 40px;
}
.img-logo img {
  vertical-align: middle;

  width: 40px;
  height: 40px;
}
.jd-title {
  font-size: 30px;
}
.show_title_left {
  padding-left: 7px;
  margin-top: 60px;
  margin-bottom: 20px;

  color: #333;
  line-height: 16px;
  font-size: 16px;
  border-left: 3px solid $main2_color;
}
// 职位描述
.description {
  line-height: 30px;
}
// 职位诱惑
.job-hot span {
  // width: 100%;
  color: #fff;
  height: 30px;
  padding: 3px 15px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: $main3_color;
  // border: 2px solid #784f39;
}
// 公司环境

.company_width img {
  width: 100%;
  height: 100%;
}
/* 轮播图 图片居中 */
.el-carousel__item {
  vertical-align: middle;
  // background-color: red;
  text-align: center;
}
.el-carousel__item img {
  vertical-align: middle;
}
.el-carousel__item span {
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.position {
  margin-right: 3px;
  border-bottom: 1px solid #f4f4f5;
}
</style>